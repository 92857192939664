import "core-js/modules/es.array.push.js";
import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import HomeAlarm from "./Alarm.vue";
import AddressLabel from "@/components/AddressLabel/";
import httpApi from "@/api/httpApi";
import { useMessage } from "@/hooks/web/useMessage";
import { ElMessageBox } from "element-plus";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import LeafletMap from "./LeafletMap.vue";
import { sleep } from "@/utils/sleep";
export default {
  name: "ContentNcbz",
  components: {
    HomeAlarm,
    AddressLabel,
    LeafletMap
  },
  props: {
    data: {
      type: Object,
      default: {
        deptNum: 0,
        cardNum: 0,
        onlineNum: 0,
        alarmNum: 0,
        attDeviceNum: 0,
        total: 0
      }
    }
  },
  setup() {
    const router = useRouter();
    const message = useMessage();
    const {
      state
    } = useStore();
    const refData = reactive({
      refBMapMarkerClusterer: null,
      refLeafletMap: null,
      homeAlarmShow: false,
      homeAlarmData: {
        alarmname: "",
        mdtid: "",
        phone: "",
        holdname: "",
        className: "",
        objectname: "",
        lon: "",
        lat: "",
        objectid: ''
      },
      left: 100,
      tableData: [],
      pollTime: null,
      pageSize: 20,
      current: 1,
      total: 0,
      tableColumns: [{
        title: '报警类型',
        width: 160,
        align: 'center',
        key: 'alarmname',
        visible: true
      }, {
        title: '设备名',
        align: 'center',
        width: 160,
        key: 'objectname',
        visible: true
      }, {
        title: '所属单位',
        align: 'center',
        key: 'holdname',
        width: 120,
        visible: true
      }, {
        title: 'IMEI号',
        align: 'center',
        key: 'mdtid',
        width: 140,
        visible: true
      }, {
        title: '报警时间',
        align: 'center',
        width: 180,
        key: 'recvtime',
        visible: true
      }, {
        title: '位置',
        width: 260,
        align: 'center',
        key: 'position',
        visible: true
      }, {
        title: '操作',
        width: 160,
        align: 'center',
        key: 'opereat',
        visible: true
      }],
      alarmIds: [],
      wordTime: null,
      ws: null,
      type: false
    });
    //跳转
    const handleLink = url => {
      router.push(url);
    };
    //文字滚动
    const handleLeft = () => {
      if (refData.left <= -50) {
        refData.left = 100;
      } else {
        refData.left -= 0.2;
      }
      refData.wordTime = setTimeout(() => {
        handleLeft();
      }, 20);
    };
    //获取报警数据 start
    const getAlramData = async () => {
      const {
        data
      } = await httpApi.noDealWithPage({
        pageSize: refData.pageSize,
        pageNo: refData.current
      });
      if (data) {
        refData.tableData = data.list.map(item => {
          return {
            ...item,
            schoolName: `${item.holdname}`
          };
        });
        refData.total = data.total;
      }
    };
    // 报警数据
    const handlePoll = () => {
      refData.current = 1;
      getAlramData();
      refData.refLeafletMap && refData.refLeafletMap.getData(1, '');
      refData.pollTime = setTimeout(() => {
        handlePoll();
      }, 30 * 1000);
    };
    //修改分页
    const currentChange = val => {
      refData.current = val;
      getAlramData();
    };
    //关闭报警详情
    const handleHide = () => {
      refData.homeAlarmShow = false;
    };
    //解除报警
    const deleteRealAlarm = item => {
      const {
        id,
        objectid,
        alarmtype,
        alarmname,
        mdtid,
        alarmSource
      } = item;
      ElMessageBox.confirm(`是否确认解除“${mdtid}设备-${alarmname}”`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true
      }).then(async () => {
        const {
          code,
          msg
        } = await httpApi.deleteRealAlarm({
          alarmid: id,
          objectid,
          alarmtype
        });
        message[code == 0 ? "success" : "warning"](code == 0 ? "成功解除" : msg);
        code == 0 && getAlramData();
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //连接实时报警
    const websocketAlarm = () => {
      const onoff = window.location.protocol === 'https:';
      const url = `${onoff ? CONFIG.wssUrl : CONFIG.wsUrl}/ws/${state.sessionId}`;
      refData.ws = new WebSocket(url);
      refData.ws.onopen = () => {
        console.log('weboskcet连接成功');
      };
      refData.ws.onclose = () => {
        console.log('weboskcet断开连接');
      };
      refData.ws.onmessage = msg => {
        if (msg.data != 1) {
          if (JSON.parse(msg.data).tag === 'heartBeat') {
            refData.ws.send('123456789');
          } else {
            if (msg.data === '123456789') return false;
            refData.homeAlarmShow = true;
            refData.homeAlarmData = JSON.parse(msg.data).data;
            refData.refLeafletMap && refData.refLeafletMap.setCurrentStudentId(refData.homeAlarmData.objectid);
            refData.tableData.unshift({
              ...refData.homeAlarmData,
              holdname: `${refData.homeAlarmData.holdname}`
            });
          }
        }
        ;
      };
    };
    // 设置地图中心点
    const panToPoint = async () => {
      refData.type = !refData.type;
      await sleep(200);
      //refData.refLeafletMap && refData.type &&  refData.refLeafletMap.panToPoint();
    };
    onMounted(async () => {
      handleLeft();
      websocketAlarm();
      // 请求地图数据
      await sleep(4500);
      refData.refLeafletMap && refData.refLeafletMap.getData(1, '');
    });
    onUnmounted(() => {
      refData.wordTime && clearTimeout(refData.wordTime);
      refData.pollTime && clearTimeout(refData.pollTime);
      refData.ws && refData.ws.close();
    });
    handlePoll();
    return {
      ...toRefs(refData),
      deleteRealAlarm,
      currentChange,
      handleHide,
      handleLink,
      panToPoint
    };
  }
};