import "core-js/modules/es.array.push.js";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "@/utils/leaflet/Control.FullScreen.css";

//  引入互联网地图插件
require("@/utils/leaflet/leaflet.ChineseTmsProviders.js");
// 引入互联网地图纠偏插件
require("@/utils/leaflet/leaflet.mapCorrection.min.js");
require("@/utils/leaflet/leaflet-rotate-src.js");
require("@/utils/leaflet/Control.FullScreen.js");
import { CanvasLabel } from "@panzhiyue/leaflet-canvaslabel";
require(`@/assets/images/map_icon_3.png`);
import httpApi from "@/api/httpApi";
import { reactive, toRefs, onMounted, nextTick, defineAsyncComponent, defineComponent, computed, unref, watch } from "vue";
import { bdEncrypt, LonLat, bdDecrypt } from '@/utils/bdGPS';
import dayjs from 'dayjs';
import { isEmpty, isNullOrUnDef } from '@/utils/is';
export default {
  name: 'LeafletMap',
  props: {
    isCustomizeStyle: {
      type: Boolean,
      default: false
    },
    isEmbed: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      _map: null,
      centerLon: null,
      centerLat: null,
      centerMarker: null,
      refMap: null,
      school: null,
      schoolId: null,
      MarkerClusterer: null,
      infoWindow: null,
      refInfo: null,
      active: null,
      points: [],
      currentCardId: null,
      mapClusterer: null,
      infoMarker: null,
      markers: []
    };
  },
  mounted() {
    // 地图初始化
    this.initMap();
  },
  methods: {
    /**
    * Debug L.Map.getCenter()
    */
    getMap() {
      return [this._map, this._map];
    },
    displayCenter: function () {
      var map = this._map;
      if (this.centerMarker) {
        this.centerMarker.remove();
      }
      this.centerMarker = L.circleMarker(map.getCenter(), {
        radius: 3,
        color: 'red'
      }).addTo(map).bindTooltip('<b>center</b>' + map.getCenter().toString(), {
        direction: 'right',
        permanent: true
      });
      this.centerMarker.getTooltip().getElement().setAttribute("tabindex", "-1");
    },
    getData: async function (type, holdid) {
      console.log("getStudentsPosByNCBZ" + holdid);
      const childFlag = 1;
      const {
        data,
        code,
        msg
      } = await httpApi.getStudentsPosBySchool({
        holdid,
        childFlag,
        type
      });
      this.points = data === null || data === void 0 ? void 0 : data.posList;
      code == 0 && (data === null || data === void 0 ? void 0 : data.posList) && this.handleOpen(data === null || data === void 0 ? void 0 : data.posList);
    },
    handleOpen: function (params) {
      nextTick(() => {
        //const [map,refMap] = getMap();
        // refMap.clearClusterer();
        if (params.length === 0) {
          //refMap.addClusterer([]);
          return;
        }
        ;
        // const markers = [];
        //this.mapClustererMarkers = [];
        let num = 0;
        const deDuplication = [];
        this.clearMapdata(0);
        this.markers = [];
        params.map((item, index) => {
          //if(item.lat != 0 && item.lon != 0 ){
          num++;
          // const { lat, lon } = bdEncrypt([item.lon, item.lat]);
          // const marker = {
          //   point:[lon,lat],
          //   acc:item,
          //   info:item
          // }
          this.updateMapdata(item, 0, 1, 0, 0, 24, 24);
          // mapClustererMarkers.push(marker);
          //}
        });
        //refMap.addClusterer(mapClustererMarkers);
      });
    },
    //清空map数据

    clearMapdata: function (id) {
      //$('#'+gMapName).gmap3({clear: {id: cardata.oid}});
      for (let i = 0; i < this.markers.length; i++) {
        let item = this.markers[i];
        if (id == item.id || id == 0) {
          item.marker.remove();
        }
      }
      this.markers = this.markers.filter(item => item.id !== id);
    },
    //添加和更新map数据,iscenter=1 (车辆居中,显示大info窗口） iscenter=2 跟踪，显示小info窗口  iscenter=3,回放
    updateMapdata: function (gpsdata, iscenter, isshow, isposition, showtype, width, height) {
      var cardata = gpsdata;
      if (cardata.lon == 0 || cardata.lat == 0) {
        return;
      }
      this.clearMapdata(cardata.objectid);
      var mappoint;
      isposition = isposition || 0;
      var width = width || 32;
      var height = height || 32;
      var showtype = showtype || 0;
      var lng = cardata.lon;
      var lat = cardata.lat;
      var carcontent = cardata.objectname;
      var iconPath = require(`@/assets/images/gps/per-0.png`); //`../../assets/images/map_icon_3.png`;
      var myIcon = L.icon({
        iconUrl: iconPath,
        iconSize: [width, height],
        popupAnchor: [-0, -22]
      });
      var options = {
        labelStyle: {
          text: "22222",
          scale: 1,
          rotation: 0,
          zIndex: 0
        }
      };
      options.icon = myIcon;
      var marker = L.marker([lat, lng], options).addTo(this._map).bindPopup(carcontent);
      marker.bindTooltip(cardata.objectname);
      this.markers.push({
        id: cardata.objectid,
        marker: marker,
        map: 'map'
      });
      if (iscenter) {
        setLocalValue("lon", lng);
        setLocalValue("lat", lat);
        setTimeout(() => {
          // 当执行多个地图操作时必须延迟执行否则没反应
          this._map.panTo([lat, lng]);
        }, 100);
      }
    },
    // 使用id为map的div容器初始化地图，同时指定地图的中心点和缩放级别
    initMap() {
      var esri = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        id: 'mapbox.streets',
        maxZoom: 24,
        preferCanvas: true,
        maxNativeZoom: 18,
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        // noWrap: true
      });
      var Gaodeimgem = L.tileLayer.chinaProvider("GaoDe.Satellite.Map", {
        maxZoom: 18,
        minZoom: 5,
        preferCanvas: true,
        zoomOffset: 0
      });
      var Gaodeimga = L.tileLayer.chinaProvider("GaoDe.Satellite.Annotion", {
        maxZoom: 18,
        minZoom: 5,
        preferCanvas: true,
        zoomOffset: 0
      });
      var Googleimgem = L.tileLayer.chinaProvider("Google.Satellite.Map", {
        maxZoom: 22,
        maxNativeZoom: 22,
        preferCanvas: true,
        detectRetina: true,
        minZoom: 1
      });
      var Googleimga = L.tileLayer.chinaProvider("Google.Satellite.Annotion", {
        minZoom: 1,
        maxZoom: 22,
        preferCanvas: true,
        detectRetina: true,
        maxNativeZoom: 22
      });
      var canvasLabel = new L.CanvasLabel({
        defaultLabelStyle: {
          collisionFlg: true,
          scale: 1,
          strokeStyle: "#000",
          fillStyle: "#fff",
          lineWidth: 3
        }
      });
      let map = L.map("map", {
        renderer: canvasLabel,
        center: [30.859888, 106.066091],
        // 中心位置
        layers: [Gaodeimga],
        zoom: 18,
        // 缩放等级
        attributionControl: false,
        // 版权控件
        zoomControl: true,
        //缩放控件
        fullscreenControl: true,
        fullscreenControlOptions: {
          // optional
          title: 'fullscreen',
          titleCancel: 'Exit fullscreen mode'
        },
        rotate: true,
        rotateControl: {
          closeOnZeroBearing: false
          // position: 'bottomleft',
        },
        trackResize: true,
        zoomDelta: 0.5,
        bearing: 0
      });
      this._map = map; // data上需要挂载
      // you can also toggle fullscreen from map object

      //var GoogleImage = L.layerGroup([Googleimgem, Googleimga]).addTo(map);
      var GaodeImage = L.layerGroup([Gaodeimgem, Gaodeimga]).addTo(map);
      var layers = L.control.layers({
        '高德': GaodeImage,
        'Satellite': esri
      }, null, {
        collapsed: false
      }).addTo(map);
      setTimeout(function () {
        map.setBearing(45);
      }, 3000);
      //map.on('moveend zoomend resetview rotate', this.displayCenter.bind(this));
    }
  }
};