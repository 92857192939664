import { reactive, toRefs, inject } from "vue";
import { ElCarousel, ElCarouselItem, ElProgress } from "element-plus";
export default {
  name: 'RightNcbz',
  components: {
    ElCarousel,
    ElCarouselItem,
    ElProgress
  },
  setup(props) {
    const refData = reactive({
      card1Ref: null,
      card2Ref: null,
      schoolRankList: [],
      content: ``,
      title: '',
      data: {
        cardNum: 0,
        activationNum: 0,
        alarmNum: 0,
        alarmDealNum: 0
      }
    });
    const provideData = inject('rootProvide');
    const initEcharts = (data, schoolRankList, total) => {
      refData.schoolRankList = schoolRankList;
      refData.data = total;
      refData.card2Ref.init(data);
    };
    return {
      ...toRefs(refData),
      provideData,
      initEcharts
    };
  }
};